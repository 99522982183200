"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VariableType = (function () {
    function VariableType(value) {
        this.value = value;
    }
    VariableType.prototype.toJSON = function () {
        return "$" + this.value;
    };
    return VariableType;
}());
exports.VariableType = VariableType;
