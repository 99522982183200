"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumType_1 = require("./types/EnumType");
var VariableType_1 = require("./types/VariableType");
exports.configFields = [
    '__args', '__alias', '__aliasFor', '__variables', '__directives', '__on', '__all_on', '__typeName', '__name'
];
function stringify(obj_from_json) {
    if (obj_from_json instanceof EnumType_1.EnumType) {
        return obj_from_json.value;
    }
    else if (obj_from_json instanceof VariableType_1.VariableType) {
        return "$" + obj_from_json.value;
    }
    else if (typeof obj_from_json !== 'object' || obj_from_json === null) {
        return JSON.stringify(obj_from_json);
    }
    else if (Array.isArray(obj_from_json)) {
        return "[" + obj_from_json.map(function (item) { return stringify(item); }).join(', ') + "]";
    }
    var props = Object
        .keys(obj_from_json)
        .map(function (key) { return key + ": " + stringify(obj_from_json[key]); })
        .join(', ');
    return "{" + props + "}";
}
function buildArgs(argsObj) {
    var args = [];
    for (var argName in argsObj) {
        args.push(argName + ": " + stringify(argsObj[argName]));
    }
    return args.join(', ');
}
function buildVariables(varsObj) {
    var args = [];
    for (var varName in varsObj) {
        args.push("$" + varName + ": " + varsObj[varName]);
    }
    return args.join(', ');
}
function buildDirectives(dirsObj) {
    var directiveName = Object.keys(dirsObj)[0];
    var directiveValue = dirsObj[directiveName];
    if (typeof directiveValue === 'boolean') {
        return directiveName;
    }
    else if (typeof directiveValue === 'object') {
        var args = [];
        for (var argName in directiveValue) {
            var argVal = stringify(directiveValue[argName]).replace(/"/g, '');
            args.push(argName + ": " + argVal);
        }
        return directiveName + "(" + args.join(', ') + ")";
    }
    else {
        throw new Error("Unsupported type for directive: " + typeof directiveValue + ". Types allowed: object, boolean.\n" +
            ("Offending object: " + JSON.stringify(dirsObj)));
    }
}
function getIndent(level) {
    return Array((level * 4) + 1).join(' ');
}
function filterNonConfigFields(fieldName, ignoreFields) {
    return exports.configFields.indexOf(fieldName) == -1 && ignoreFields.indexOf(fieldName) == -1;
}
function convertQuery(node, level, output, options) {
    Object.keys(node)
        .filter(function (key) { return filterNonConfigFields(key, options.ignoreFields); })
        .forEach(function (key) {
        var value = node[key];
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                value = value.find(function (item) { return item && typeof item === 'object'; });
                if (!value) {
                    output.push(["" + key, level]);
                    return;
                }
            }
            var fieldCount = Object.keys(value)
                .filter(function (keyCount) { return filterNonConfigFields(keyCount, options.ignoreFields); }).length;
            var subFields = fieldCount > 0;
            var argsExist = typeof value.__args === 'object' && Object.keys(value.__args).length > 0;
            var directivesExist = typeof value.__directives === 'object';
            var fullFragmentsExist = value.__all_on instanceof Array;
            var partialFragmentsExist = typeof value.__on === 'object';
            var token = "" + key;
            if (typeof value.__name === 'string') {
                token = token + " " + value.__name;
            }
            if (typeof value.__aliasFor === 'string') {
                token = token + ": " + value.__aliasFor;
            }
            if (typeof value.__variables === 'object') {
                token = token + " (" + buildVariables(value.__variables) + ")";
            }
            else if (argsExist || directivesExist) {
                var argsStr = '';
                var dirsStr = '';
                if (directivesExist) {
                    dirsStr = Object.entries(value.__directives)
                        .map(function (item) {
                        var _a;
                        return "@" + buildDirectives((_a = {}, _a[item[0]] = item[1], _a));
                    })
                        .join(' ');
                }
                if (argsExist) {
                    argsStr = "(" + buildArgs(value.__args) + ")";
                }
                var spacer = directivesExist && argsExist ? ' ' : '';
                token = token + " " + dirsStr + spacer + argsStr;
            }
            output.push([token + (subFields || partialFragmentsExist || fullFragmentsExist ? ' {' : ''), level]);
            convertQuery(value, level + 1, output, options);
            if (fullFragmentsExist) {
                value.__all_on.forEach(function (fullFragment) {
                    output.push(["..." + fullFragment, level + 1]);
                });
            }
            if (partialFragmentsExist) {
                var inlineFragments = value.__on instanceof Array ? value.__on : [value.__on];
                inlineFragments.forEach(function (inlineFragment) {
                    var name = inlineFragment.__typeName;
                    output.push(["... on " + name + " {", level + 1]);
                    convertQuery(inlineFragment, level + 2, output, options);
                    output.push(['}', level + 1]);
                });
            }
            if (subFields || partialFragmentsExist || fullFragmentsExist) {
                output.push(['}', level]);
            }
        }
        else if (options.includeFalsyKeys === true || value) {
            output.push(["" + key, level]);
        }
    });
}
function jsonToGraphQLQuery(query, options) {
    if (options === void 0) { options = {}; }
    if (!query || typeof query != 'object') {
        throw new Error('query object not specified');
    }
    if (Object.keys(query).length == 0) {
        throw new Error('query object has no data');
    }
    if (!(options.ignoreFields instanceof Array)) {
        options.ignoreFields = [];
    }
    var queryLines = [];
    convertQuery(query, 0, queryLines, options);
    var output = '';
    queryLines.forEach(function (_a) {
        var line = _a[0], level = _a[1];
        if (options.pretty) {
            if (output) {
                output += '\n';
            }
            output += getIndent(level) + line;
        }
        else {
            if (output) {
                output += ' ';
            }
            output += line;
        }
    });
    return output;
}
exports.jsonToGraphQLQuery = jsonToGraphQLQuery;
